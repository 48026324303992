import { Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import Dropzone from 'react-dropzone';
import AppController from '../../../../../base/App.controller';
import uploadCompleteIcon from '../../../../../images/upload-complete.svg';
import uploadRequestIcon from '../../../../../images/upload-request.svg';
import ButtonWrapper from '../../../wrappers/ButtonWrapper';
import DialogWrapper from '../../../wrappers/DialogWrapper';
import { IImportConfigProps } from '../../types';
import { Itranslation } from 'types';

const ImportConfigurationModal: React.FC<IImportConfigProps> = ({
    AppStore,
    BankConfigurationStore,
}) => {
    const { t }: Itranslation = AppController.getTranslation(AppStore.scope, 'bank-configuration');
    const {
        closeModal,
        file,
        invalidFileFormat,
        isOpen,
        loading,
        onDrop,
        tooManyFiles,
        handleBankRegistrationGroup,
    } = BankConfigurationStore!.importModalStore;

    function _getText(open: () => void) {
        if (file) {
            return (
                <div>
                    <img src={uploadCompleteIcon} />
                    <div className={'sectionTitle'}>{t('uploadComplete')}</div>
                    <div className={'fileName'}>{file.name}</div>
                    <div>
                        <span>{t('common:or')}</span>
                        &nbsp;
                        <span className={'link'} onClick={open}>
                            {t('chooseDifferentFile')}
                        </span>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <img src={uploadRequestIcon} />
                <div className={'sectionTitle'}>{t('dropFile')}</div>
                <div>
                    <span>{t('common:or')}</span>
                    &nbsp;
                    <span className={'link'} onClick={open}>
                        {t('browse')}
                    </span>
                    <span className={'errorText'}>
                        {invalidFileFormat && t('invalidFormat')}
                        {tooManyFiles && t('tooManyFiles')}
                    </span>
                </div>
            </div>
        );
    }

    return (
        <DialogWrapper
            open={isOpen}
            onClose={closeModal}
            className={'ImportConfigurationModal'}
            title={t('importModalTitle')}>
            <div className={'description'}>{t('description')}</div>
            <div>
                <Dropzone accept=".csv" onDrop={onDrop} noClick noKeyboard>
                    {({ getRootProps, getInputProps, open }) => (
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className={'uploadSection'}>{_getText(open)}</div>
                        </div>
                    )}
                </Dropzone>
            </div>
            <div className={'buttonContainer'}>
                <Grid container justify="space-between" spacing={5}>
                    <Grid item xs={6}>
                        <ButtonWrapper
                            color="secondary"
                            className={'closeButton'}
                            onClick={closeModal}>
                            {t('common:cancel')}
                        </ButtonWrapper>
                    </Grid>
                    <Grid item xs={6}>
                        <ButtonWrapper
                            size={'large'}
                            color="primary"
                            className={'importButton'}
                            loading={loading}
                            disabled={!file}
                            onClick={() => handleBankRegistrationGroup(AppStore, t)}>
                            {t('common:import')}
                        </ButtonWrapper>
                    </Grid>
                </Grid>
            </div>
        </DialogWrapper>
    );
};

export default inject('AppStore', 'BankConfigurationStore')(observer(ImportConfigurationModal));
