import { Grid, MenuItem } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import React from 'react';
import AppController from 'base/App.controller';
import FormWrapper from 'components/common/wrappers/FormWrapper';
import SelectWrapper from 'components/common/wrappers/SelectWrapper';
import TextFieldWrapper from 'components/common/wrappers/TextFieldWrapper';
import { QSEAL, QWAC } from 'constants/cert';
import { IBankStandardsProps } from 'components/common/modals/types';
import { Itranslation } from 'types';

const PolishApiDefault: React.FC<IBankStandardsProps> = ({ AppStore, BankConfigurationStore }) => {
    const { t }: Itranslation = AppController.getTranslation(AppStore.host, [
        'bank-configuration',
        'common',
    ]);

    const {
        form,
        isFormChanged,
        handleSave,
        handleChange,
        handleCertChange,
        signingKeyCertificates,
        transportKeyCertificates,
    } = BankConfigurationStore!.defaultSettingsModalStore;
    const ref = React.createRef();

    const handleSubmit = () => {
        return handleSave(AppStore);
    };

    return (
        <FormWrapper
            className="form sectionParameters"
            formRef={ref}
            onSubmit={handleSubmit}
            isValid={isFormChanged}>
            <Grid container spacing={4}>
                <Grid item xs={6}>
                    <TextFieldWrapper
                        name="jwtSigningKeyId"
                        type="text"
                        label={t('jwtSigningKeyId')}
                        value={form.PolishAPI.jwtSigningKeyId}
                        onChange={handleChange}
                        hideOptional
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldWrapper
                        name="clientId"
                        type="text"
                        label={t('clientId')}
                        value={form.PolishAPI.clientId}
                        onChange={handleChange}
                        hideOptional
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldWrapper
                        name="clientSecret"
                        type="text"
                        label={t('clientSecret')}
                        value={form.PolishAPI.clientSecret}
                        onChange={handleChange}
                        hideOptional
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldWrapper
                        name="x5u"
                        type="text"
                        label={t('x5u')}
                        value={form.PolishAPI.x5u}
                        onChange={handleChange}
                        hideOptional
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldWrapper
                        name="x5c"
                        type="text"
                        label={t('x5c')}
                        value={form.PolishAPI.x5c}
                        onChange={handleChange}
                        hideOptional
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldWrapper
                        name="x5t"
                        type="text"
                        label={t('x5t')}
                        value={form.PolishAPI.x5t}
                        onChange={handleChange}
                        hideOptional
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextFieldWrapper
                        name="kid"
                        type="text"
                        label={t('kid')}
                        value={form.PolishAPI.kid}
                        onChange={handleChange}
                        hideOptional
                    />
                </Grid>
                <Grid item xs={12}>
                    <SelectWrapper
                        name="signingKeyCert"
                        label={t('selectSigningKeyCert')}
                        value={form.PolishAPI.signingKeyCertName}
                        onChange={handleCertChange}
                        hideOptional>
                        {signingKeyCertificates
                            .filter((s: any) => s.type === QSEAL || s.type === 'none')
                            .map((s: any) => (
                                <MenuItem key={s.name} value={s.name}>
                                    {s.name}
                                </MenuItem>
                            ))}
                    </SelectWrapper>
                </Grid>
                <Grid item xs={12}>
                    <TextFieldWrapper
                        name="signingKeyId"
                        type="text"
                        label={t('signingKeyId')}
                        value={form.PolishAPI.signingKeyId || ''}
                        onChange={handleChange}
                        disabled
                        hideOptional
                    />
                </Grid>
                <Grid item xs={12}>
                    <SelectWrapper
                        name="transportKeyCert"
                        label={t('selectTransportKeyCert')}
                        value={form.PolishAPI.transportKeyCertName}
                        onChange={handleCertChange}
                        hideOptional>
                        {transportKeyCertificates
                            .filter((s: any) => s.type === QWAC || s.type === 'none')
                            .map((s: any) => (
                                <MenuItem key={s.name} value={s.name}>
                                    {s.name}
                                </MenuItem>
                            ))}
                    </SelectWrapper>
                </Grid>
                <Grid item xs={12}>
                    <TextFieldWrapper
                        name="transportKeyId"
                        type="text"
                        label={t('transportKeyId')}
                        value={form.PolishAPI.transportKeyId || ''}
                        onChange={handleChange}
                        disabled
                        hideOptional
                    />
                </Grid>
            </Grid>
        </FormWrapper>
    );
};

export default inject('AppStore', 'BankConfigurationStore')(observer(PolishApiDefault));
