import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import { IOperationMenuProps } from '../../types';

const OperationMenu: React.FC<IOperationMenuProps> = ({
    t,
    className,
    bankEnabled,
    onAdd,
    onEdit,
    onDelete,
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<any>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const _handleClose = (_e: React.MouseEvent<HTMLLIElement>, cb?: () => void) => {
        handleClose();
        return cb && cb();
    };

    return (
        <div className={className}>
            <IconButton
                aria-controls="fade-menu"
                aria-haspopup="true"
                className={'iconButton'}
                onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="edit-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}>
                {!bankEnabled ? (
                    <MenuItem className={'addButton'} onClick={(e) => _handleClose(e, onAdd)}>
                        {t('addButton')}
                    </MenuItem>
                ) : (
                    <div>
                        <MenuItem
                            className={'modifyButton'}
                            onClick={(e) => _handleClose(e, onEdit)}>
                            {t('modifyButton')}
                        </MenuItem>
                        <MenuItem
                            className={'deleteButton'}
                            onClick={(e) => _handleClose(e, onDelete)}>
                            {t('deleteButton')}
                        </MenuItem>
                    </div>
                )}
            </Menu>
        </div>
    );
};

export default OperationMenu;
