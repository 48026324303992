import { inject, observer } from 'mobx-react';
import React from 'react';
import AppController from '../../../../base/App.controller';
import warning from '../../../../images/warning.svg';
import ButtonWrapper from '../../wrappers/ButtonWrapper';
import DialogWrapper from '../../wrappers/DialogWrapper';
// types
import { IGenericWarningModalProps } from '../types';
import { Itranslation } from 'types';

const GenericWarningModal: React.FC<IGenericWarningModalProps> = ({
    AppStore,
    Store,
    title,
    subtitle,
    buttonText,
    onClick,
    loading,
}) => {
    const { t }: Itranslation = AppController.getTranslation(AppStore.scope, 'common');

    return (
        <DialogWrapper
            open={Store.isOpen}
            onClose={Store.closeModal}
            className={'WarningModal'}
            hideDivider>
            <img className="warning-img" src={warning} />
            <h2>{title}</h2>
            {subtitle && <div className={'description'}>{subtitle}</div>}
            <div className={'buttonContainer'}>
                <ButtonWrapper
                    variant="contained"
                    color="secondary"
                    className={'closeButton'}
                    fullWidth={false}
                    onClick={Store.closeModal}>
                    {t('common:cancel')}
                </ButtonWrapper>
                <ButtonWrapper
                    variant="contained"
                    color="primary"
                    className={'deleteButton'}
                    loading={loading}
                    fullWidth={false}
                    onClick={() => onClick(AppStore)}>
                    {buttonText}
                </ButtonWrapper>
            </div>
        </DialogWrapper>
    );
};

export default inject('AppStore')(observer(GenericWarningModal));
