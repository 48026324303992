import {
    ClickAwayListener,
    Divider,
    Grid,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    TableCell,
    TextField,
    withStyles,
    IconButton,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { inject, observer } from 'mobx-react';
import Router from 'next/router';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import snackbar from 'hooks/useSnackbar';
import AppController from '../../base/App.controller';
import { NO, YES } from '../../constants/configAvailable';
import { countries } from '../../constants/countries';
import { ALL } from 'constants/bankStandards';
import DefaultContainer from '../../layout/DefaultContainer';
import Breadcrumbs from '../common/breadcrumbs/Breadcrumbs';
import DefaultSettingsModal from '../common/modals/bank-configuration/default-settings/DefaultSettings';
import ImportConfigurationModal from '../common/modals/bank-configuration/import-configuration/ImportConfiguration';
import NextGenPsd2Modal from '../common/modals/bank-configuration/next-gen-psd2/NextGenPsd2';
import OperationMenu from '../common/modals/bank-configuration/operations/OperationMenu';
import PolishApiModal from '../common/modals/bank-configuration/polish-api/PolishApi';
import StetPsd2Modal from '../common/modals/bank-configuration/stet-psd2/StetPsd2';
import UKOpenBankingModal from '../common/modals/bank-configuration/uk-open-banking/UKOpenBanking';
import GenericWarningModal from '../common/modals/warning/GenericWarning';
import CustomThemeProvider from '../common/theme/CustomThemeProvider';
import ButtonWrapper from '../common/wrappers/ButtonWrapper';
import SelectWrapper from '../common/wrappers/SelectWrapper';
import TableWrapper, { cellConstructor } from '../common/wrappers/TableWrapper';

const { useSnackbar } = snackbar;

const StyledIcon = withStyles({
    root: {
        borderRadius: '0 5px 5px 0',
        backgroundColor: 'var(--primary)',
        color: 'white',
        height: '96.5%',
        '&:disabled': {
            backgroundColor: 'var(--primary)',
            color: 'white',
            opacity: '0.2',
        },
    },
})(IconButton);

let currentSnackbarType = 'fetch';

function BankConfigurationMain({ AppStore, BankConfigurationStore }) {
    const { t } = AppController.getTranslation(AppStore.host, ['bank-configuration', 'common']);

    const {
        loading,
        handleFetch,
        getDefaultSettings,
        provideDefaultSettings,
        handleSelect,
        handleAutocomplete,
        getFilteredBanks,
        enableFilter,
        banks,
        isBankSelected,
        filterOptions,
        handleFilter,
        handleClear,
        selectedBank,
        updateSelectRow,
        standardOptions,
        TableWrapperStore,
        openImportModal,
        bankConfigurationModalStore,
        deleteModalStore,
        bankRegistrationGroups,
        handleExport,
        options,
        bankRegistrationGroupIds,
        snackbarKeys,
        updateSnackbarKeys,
        snackBarType,
        openSnackbar,
        rootStore,
        isTpp,
    } = BankConfigurationStore;

    const { addBankConfiguration, modifyBankConfiguration } = bankConfigurationModalStore;

    useEffect(() => {
        getDefaultSettings(AppStore.member);
        handleFetch(AppStore);
    }, []);

    const { addSnack, closeSnack } = useSnackbar();
    useEffect(() => {
        const props = {
            variant: 'info',
        };
        if (openSnackbar) {
            if (snackBarType === 'export') {
                currentSnackbarType = 'export';
                closeSnack(snackbarKeys['filter']);
                addSnack(t('loadingExport'), props);
            } else if (snackBarType === 'filter') {
                currentSnackbarType = 'filter';
                addSnack(t('loadingFilter'), props);
            } else {
                currentSnackbarType = 'fetch';
                addSnack(t('loadingFetch'), props);
            }
        } else {
            // hide snackbar programatically
            closeSnack(snackbarKeys['fetch']);
            closeSnack(snackbarKeys['filter']);
            closeSnack(snackbarKeys['export']);
        }
        updateSnackbarKeys(currentSnackbarType, currentSnackbarType);
    }, [openSnackbar, snackBarType]);

    const _renderButtons = () => {
        return (
            <Grid container justify="flex-end" spacing={3}>
                <Grid item xs={12} sm={6}>
                    <ButtonWrapper className={'importButton'} onClick={openImportModal}>
                        {t('common:import')}
                    </ButtonWrapper>
                </Grid>
            </Grid>
        );
    };

    const bankText = (row) => {
        const value = row.identifier ? `${row.bankName}-${row.identifier}` : row.bankName;
        return cellConstructor.text({ row, keyId: 'bankId', value });
    };

    const header = [
        t('country'),
        t('bankName'),
        t('bankRegistrationGroup'),
        t('standard'),
        t('configAvailable'),
        '',
    ];

    const cellTemplates = [
        (row) => cellConstructor.text({ row, keyId: 'bankId', field: 'country' }),
        (row) => bankText(row),
        (row) => cellConstructor.text({ row, keyId: 'bankId', field: 'bankRegistrationGroup' }),
        (row) =>
            cellConstructor.text({
                row,
                keyId: 'bankId',
                field: 'standard',
                value: t(`standards.${row['standard']}`),
            }),
        (row) => cellConstructor.text({ row, keyId: 'bankId', field: 'configAvailable' }),
        (row) => (
            <TableCell
                key={`${row['bankId']}_menu`}
                align={'center'}
                onClick={() => updateSelectRow(row)}
                className={'menu'}>
                <OperationMenu
                    t={t}
                    bankEnabled={row.configAvailable === YES}
                    onAdd={addBankConfiguration}
                    onEdit={() => modifyBankConfiguration(AppStore)}
                    onDelete={() =>
                        deleteModalStore.handleBankRegistrationGroup(AppStore, 'delete')
                    }
                />
            </TableCell>
        ),
    ];

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const _renderExportButton = () => {
        return (
            <Grid container justify="flex-end" ref={anchorRef}>
                <Grid item xs={12} sm={8}>
                    <ButtonWrapper
                        onClick={() =>
                            handleExport(
                                AppStore,
                                filterOptions.standard,
                                options[selectedIndex],
                                t,
                            )
                        }
                        className={'exportButton'}
                        disabled={!filterOptions.standard}
                        showTooltip
                        tooltipText={t('tooltips.export')}>
                        {t('common:export')}
                    </ButtonWrapper>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <StyledIcon
                        className={'dropButton'}
                        onClick={handleToggle}
                        disabled={!filterOptions.standard}>
                        <ArrowDropDownIcon />
                    </StyledIcon>
                </Grid>
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal>
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="split-button-menu">
                                {options.map((option, index) => (
                                    <MenuItem
                                        className={option}
                                        key={option}
                                        selected={index === selectedIndex}
                                        onClick={(event) => handleMenuItemClick(event, index)}>
                                        {t(option)}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Popper>
            </Grid>
        );
    };

    const _previousStep = () => {
        return Router.push('/certificate-management');
    };

    return (
        <div className={'bankConfigurationMain'}>
            <DefaultContainer>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Breadcrumbs
                            previousStep={isTpp ? rootStore.previousStep : _previousStep}
                            parentPath={t('parentTitle')}
                            currentPath={t('title')}
                        />
                    </Grid>
                    <Grid item xs={3} />
                    <Grid item xs={3}>
                        <ButtonWrapper
                            className={'defaultSettingsButton'}
                            color={'secondary'}
                            onClick={provideDefaultSettings}>
                            {t('defaultSettings')}
                        </ButtonWrapper>
                    </Grid>
                </Grid>
                <Divider />
                <div className={'descriptions'}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={8}>
                            <div>{t('defaultSettingsInstructions')}</div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            {_renderButtons()}
                        </Grid>
                    </Grid>
                </div>
                <Divider />
                <div className={'filterOptions'}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={2}>
                            <SelectWrapper
                                name="country"
                                label={t('country')}
                                value={filterOptions.country}
                                onChange={handleSelect}
                                disabled={isBankSelected}
                                required>
                                <MenuItem key={ALL} value={ALL}>
                                    {t('all')}
                                </MenuItem>
                                {countries.map((c) => (
                                    <MenuItem key={c.code} value={c.code}>
                                        {c.name}
                                    </MenuItem>
                                ))}
                            </SelectWrapper>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Autocomplete
                                id="bankId"
                                name="bankId"
                                options={banks}
                                value={selectedBank}
                                getOptionLabel={(option) =>
                                    option.identifier
                                        ? `${option.name}-${option.identifier}`
                                        : option.name
                                }
                                onChange={handleAutocomplete}
                                onInputChange={getFilteredBanks}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name={'bankName'}
                                        label={t('bankName')}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <SelectWrapper
                                name="bankRegistrationGroup"
                                label={t('bankRegistrationGroup')}
                                value={filterOptions.bankRegistrationGroup}
                                onChange={handleSelect}
                                hideOptional>
                                {bankRegistrationGroups.map((s) => (
                                    <MenuItem key={s} value={s}>
                                        {s}
                                    </MenuItem>
                                ))}
                            </SelectWrapper>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <SelectWrapper
                                name="standard"
                                label={t('standard')}
                                value={filterOptions.standard}
                                onChange={handleSelect}
                                required>
                                {standardOptions.map((s) => (
                                    <MenuItem key={s} value={s}>
                                        {t(`standards.${s}`)}
                                    </MenuItem>
                                ))}
                            </SelectWrapper>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <SelectWrapper
                                name="configAvailable"
                                label={t('configAvailable')}
                                value={filterOptions.configAvailable}
                                onChange={handleSelect}
                                required>
                                {[YES, NO].map((s) => (
                                    <MenuItem key={s} value={s}>
                                        {s}
                                    </MenuItem>
                                ))}
                            </SelectWrapper>
                        </Grid>
                    </Grid>
                </div>
                <Grid container justify="flex-end" className={'export'} spacing={3}>
                    <Grid item xs={12} sm={2}>
                        <ButtonWrapper
                            className={'clearButton'}
                            onClick={() => handleClear(AppStore)}>
                            {t('clear')}
                        </ButtonWrapper>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <ButtonWrapper
                            className={'filterButton'}
                            onClick={() => handleFilter(AppStore)}
                            disabled={!enableFilter}>
                            {t('filter')}
                        </ButtonWrapper>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        {_renderExportButton()}
                    </Grid>
                </Grid>
                <TableWrapper
                    header={header}
                    enablePagination
                    disableRowSelect
                    cellTemplates={cellTemplates}
                    noDataMessage={t('noBankConfigs')}
                    loading={loading}
                    TableWrapperStore={TableWrapperStore}
                />
                <CustomThemeProvider alt>
                    <DefaultSettingsModal />
                    <UKOpenBankingModal />
                    <PolishApiModal />
                    <NextGenPsd2Modal />
                    <StetPsd2Modal />
                    <ImportConfigurationModal />
                    <GenericWarningModal
                        Store={BankConfigurationStore.deleteModalStore}
                        onClick={() =>
                            BankConfigurationStore.deleteModalStore.handleDelete(AppStore)
                        }
                        title={t('warningModalTitle')}
                        subtitle={
                            bankRegistrationGroupIds.length
                                ? t('bRG-warning', { bankRegistrationGroupIds })
                                : ''
                        }
                        buttonText={t('delete')}
                        loading={BankConfigurationStore.deleteModalStore.loading}
                    />
                    <GenericWarningModal
                        Store={BankConfigurationStore.warningModalStore}
                        onClick={() =>
                            BankConfigurationStore.bankConfigurationModalStore.handleSave(AppStore)
                        }
                        title={t('warningModalTitle')}
                        subtitle={t('bRG-warning', { bankRegistrationGroupIds })}
                        buttonText={t('common:save')}
                        loading={BankConfigurationStore.bankConfigurationModalStore.loading}
                    />
                    <GenericWarningModal
                        Store={BankConfigurationStore.importWarningModalStore}
                        onClick={() =>
                            BankConfigurationStore.importModalStore.handleImport(AppStore)
                        }
                        title={t('warningModalTitle')}
                        subtitle={
                            bankRegistrationGroupIds.length < 4
                                ? t('bRG-warning', { bankRegistrationGroupIds })
                                : t('bRG-warning-multi', {
                                    bankRegistrationGroupIds: bankRegistrationGroupIds.slice(
                                        0,
                                        3,
                                    ),
                                    bankRegistrationGroupIdsLength:
                                          bankRegistrationGroupIds.length - 3,
                                })
                        }
                        buttonText={t('common:import')}
                        loading={BankConfigurationStore.importModalStore.loading}
                    />
                </CustomThemeProvider>
            </DefaultContainer>
        </div>
    );
}

BankConfigurationMain.propTypes = {
    AppStore: PropTypes.object.isRequired,
    BankConfigurationStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'BankConfigurationStore')(observer(BankConfigurationMain));
