import React from 'react';
import { NEXT_GEN_PSD2, POLISH_API, STET_PSD2 } from '../../../../../constants/bankStandards';
import NextGenPsd2Default from './next-gen-psd2/NextGenPsd2Default';
import PolishApiDefault from './polish-api/PolishApiDefault';
import StetPsd2Default from './stet-psd2/StetPsd2Default';
import UkOpenBankingDefault from './uk-open-banking/UkOpenBankingDefault';

export default class DefaultSettingsController {
    static getComponent(selectedStandard: string) {
        switch (selectedStandard) {
            case POLISH_API:
                return <PolishApiDefault />;
            case NEXT_GEN_PSD2:
                return <NextGenPsd2Default />;
            case STET_PSD2:
                return <StetPsd2Default />;
            default:
                return <UkOpenBankingDefault />;
        }
    }
}
