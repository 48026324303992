import { useSnackbar, OptionsObject } from 'notistack';
import Slide from '@material-ui/core/Slide';

// Purpose of this hook is to add the default settings to each snack bar item
export default {
    useSnackbar: function _useSnackbar() {
        const { enqueueSnackbar, closeSnackbar } = useSnackbar();
        return {
            addSnack: (message: string, props?: OptionsObject) => {
                enqueueSnackbar(message, {
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                    TransitionComponent: Slide,
                    ...props,
                } as OptionsObject);
            },
            closeSnack: (key: number | string) => {
                closeSnackbar(key);
            },
        };
    },
};
